import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
// import { notification } from '../../common/Notifications';
// import { reload, useAppSelector } from '../../store/store';
// import { AppMenuItem } from '../atoms/AppMenuItem';
// import { AvatarItem } from '../atoms/AvatarItem';
// import Backdrop from '../molecules/Backdrop';
// import { TenantSelector } from '../molecules/TenantSelector';
// import { ModalView } from './ModalView';
import { SideMenu } from '@pricer/pricer-ui';
// import { TagSharp } from '@mui/icons-material';
// import ApiIcon from '@mui/icons-material/Api';
// import CableIcon from '@mui/icons-material/Cable';
// import LogoutIcon from '@mui/icons-material/Logout';
// import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
// import { Drawer, List } from '@mui/material';
import { useNavigate } from 'react-router';
import { useGetLinksQuery } from '../../api/linksApi';
import { useGetProfileQuery } from '../../api/profileApi';
// import UserIcon from '../../common/assets/users/plain_user.svg';
import { useAuthentication } from '../../common/hooks/authentication';
export var AppMenu = function () {
    var profile = useGetProfileQuery().data;
    var globalLinks = useGetLinksQuery().data;
    var _a = useAuthentication(), 
    // switchTenant,
    isPricerTenant = _a.isPricerTenant, 
    // isPricerEmployee,
    role = _a.role;
    var logout = useAuth0().logout;
    var navigator = useNavigate();
    // const [open, setOpen] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isTenantSelectorOpen, setIsTenantSelectorOpen] = useState(false);
    // const token = useAppSelector((store) => store.auth.token);
    // const isAdminSide =
    //   window.location.pathname === '/admin/users' && isPricerEmployee();
    // const switchToTenant = async (tenantId: string) => {
    //   try {
    //     setIsTenantSelectorOpen(false);
    //     setOpen(false);
    //     setIsLoading(true);
    //     await switchTenant(tenantId);
    //     localStorage.setItem('currentTenant', tenantId);
    //     reload();
    //     navigator('/');
    //   } catch (_error) {
    //     notification.error('Failed to switch tenant');
    //   }
    //   setIsLoading(false);
    // };
    // Application links (will appear under "management" on side menu)
    var appLinks = {
        parentId: 'management',
        links: [
            {
                name: 'Users',
                url: '/users',
                icon: 'us1'
            },
            {
                name: 'Tags',
                url: '/tags',
                icon: 'ci1'
            },
            {
                name: 'M2M Access Keys',
                url: '/access-keys',
                icon: 'ak1'
            }
        ]
    };
    var handleAppClick = function (link) {
        window.open(link.url, '_self'); // TODO: implements proper URL navigation
    };
    /**
     * Used to handle the click event of the app links
     *
     * @param link
     */
    var handleAppLinkClick = function (link) {
        var url = link.url;
        if (url === '/users') {
            navigator(isPricerTenant() ? '/admin/users' : '/users');
        }
        else {
            navigator(url);
        }
    };
    if (!profile)
        return null; // TODO: fix this
    return (_jsx(SideMenu, { user: {
            given_name: profile === null || profile === void 0 ? void 0 : profile.givenName,
            family_name: profile === null || profile === void 0 ? void 0 : profile.familyName,
            email: profile === null || profile === void 0 ? void 0 : profile.email,
            roles: [role],
            name: (profile === null || profile === void 0 ? void 0 : profile.givenName) + ' ' + (profile === null || profile === void 0 ? void 0 : profile.familyName)
        }, tenant: profile === null || profile === void 0 ? void 0 : profile.tenant, appLinks: appLinks, onAppLinkClick: handleAppLinkClick, onLinkClick: handleAppClick, links: globalLinks, onLogOutClick: function () {
            return logout({ logoutParams: { returnTo: window.location.origin } });
        } }));
    // return (
    //   <Drawer
    //     onMouseEnter={() => setOpen(true)}
    //     onMouseLeave={() => setOpen(false)}
    //     PaperProps={{
    //       sx: {
    //         background: `linear-gradient(to right, ${isAdminSide ? '#E3BCBC' : '#F3F6F3'} 3.75rem, #FFFFFF 3.813rem)`,
    //         width: open ? '17.5rem' : '3.75rem',
    //         flexShrink: 0,
    //         transition: 'width 0.3s ease-in-out'
    //       }
    //     }}
    //     variant="permanent"
    //   >
    //     <Backdrop isLoading={isLoading} />
    //     <ModalView
    //       isOpen={isTenantSelectorOpen}
    //       maxWidth={'88.875rem'}
    //       maxHeight={'61.375rem'}
    //       onClose={() => setIsTenantSelectorOpen(false)}
    //     >
    //       <TenantSelector onClick={(id) => switchToTenant(id)} />
    //     </ModalView>
    //     <List
    //       sx={{
    //         marginTop: '2rem',
    //         flex: 1
    //       }}
    //     >
    //       <AppMenuItem
    //         onClick={() => {
    //           setIsTenantSelectorOpen(true);
    //           setOpen(false);
    //         }}
    //         primaryText={profile?.tenant.name ?? 'loading'}
    //         icon={<StoreOutlinedIcon />}
    //       />
    //       <AppMenuItem
    //         onClick={() =>
    //           navigator(isPricerTenant() ? '/admin/users' : '/users')
    //         }
    //         primaryText="Users"
    //         icon={<UserIcon />}
    //       />
    //       {!isPricerTenant() && (
    //         <>
    //           <AppMenuItem
    //             onClick={() => navigator('/tags')}
    //             primaryText="Tags"
    //             icon={<TagSharp />}
    //           />
    //           <AppMenuItem
    //             onClick={() => navigator('/access-keys')}
    //             primaryText="Access Keys"
    //             icon={<ApiIcon />}
    //           />
    //         </>
    //       )}
    //       {isPricerTenant() && (
    //         <AppMenuItem
    //           onClick={() => navigator('/admin/connections')}
    //           primaryText="Connections"
    //           icon={<CableIcon />}
    //         />
    //       )}
    //     </List>
    //     <List
    //       disablePadding
    //       sx={{
    //         pb: '1.5rem',
    //         background: `linear-gradient(to right, ${isAdminSide ? '#BF9F9F' : '#DDE9DB'} 3.75rem, #FFFFFF 3.813rem)`
    //       }}
    //     >
    //       <AppMenuItem
    //         buttonSx={{ paddingLeft: '0.563rem' }}
    //         primaryText={`${profile?.givenName ?? 'loading...'} ${profile?.familyName ?? ''}`}
    //         secondaryText={profile?.email ?? 'loading...'}
    //         icon={
    //           <AvatarItem
    //             sx={{ minWidth: 0 }}
    //             givenName={profile?.givenName}
    //             familyName={profile?.familyName}
    //           />
    //         }
    //       />
    //       <AppMenuItem
    //         onClick={() =>
    //           logout({ logoutParams: { returnTo: window.location.origin } })
    //         }
    //         primaryText={'Sign Out'}
    //         icon={<LogoutIcon />}
    //       />
    //     </List>
    //   </Drawer>
    // );
};
